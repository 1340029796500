import axios from '@/common/axios'
import qs from 'qs'

export function addCamp (data) {
  return axios({
    method: 'post',
    url: '/camp/add',
    data: qs.stringify(data)
  })
}

export function deleteCamp (data) {
  return axios({
    method: 'post',
    url: '/camp/delete',
    data: qs.stringify(data)
  })
}

export function updateCamp (data) {
  return axios({
    method: 'put',
    url: '/camp/update2',
    data: qs.stringify(data)
  })
}

export function selectCampInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}

export function selectCampList (query) {
  return axios({
    method: 'get',
    url: '/camp/list',
    params: query
  })
}

export function review (data) {
  return axios({
    method: 'put',
    url: '/camp/audit',
    data: qs.stringify(data)
  })
}

export function output (query) {
  return axios({
    method: 'get',
    url: '/camp/output',
    params: query,
    responseType: 'blob'
  })
}

export function perfectList (query) {
  return axios({
    method: 'get',
    url: '/camp/perfect/list',
    params: query
  })
}

export function perfectInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/perfect/info/' + id
  })
}

export function perfectAudit (data) {
  return axios({
    method: 'put',
    url: '/camp/perfect/audit',
    data: qs.stringify(data)
  })
}

export function addAlbum (data) {
  return axios({
    method: 'post',
    url: '/album/add',
    data: qs.stringify(data)
  })
}

export function deleteAlbum (id) {
  return axios({
    method: 'delete',
    url: '/album/delete/' + id
  })
}

export function selectAlbumList (query) {
  return axios({
    method: 'get',
    url: '/album/app/list',
    params: query
  })
}

export function removeCover (data) {
  return axios({
    method: 'post',
    url: '/album/app/cover/delete',
    data: qs.stringify(data)
  })
}

export function asCover (data) {
  return axios({
    method: 'post',
    url: '/album/app/cover/add',
    data: qs.stringify(data)
  })
}
