<template>
  <div id="activity">
    <el-dialog
      :title="activityFormTitle"
      width="880px"
      :visible.sync="activityDialogVisible"
      :close-on-click-modal="false"
      @close="activityDialogClose"
    >
      <el-form
        ref="activityFormRef"
        :model="activityForm"
        :rules="activityFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="活动名称" prop="name">
              <el-input v-model="activityForm.name" placeholder="请输入活动名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动封面" prop="cover">
              <el-upload
                ref="uploadRef"
                action=""
                list-type="picture-card"
                :http-request="handleHttp"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
                :file-list="fileList"
                :limit="1"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营地" prop="campId">
              <el-select
                v-model="activityForm.campId"
                placeholder="请选择营地"
                clearable
                filterable
                remote
                :remote-method="campRemoteMethod"
                @change="campChange"
              >
                <el-option
                  v-for="item in campList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址" prop="address">
              <el-input v-model="activityForm.address" placeholder="请输入地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经度" prop="lng">
              <el-input v-model="activityForm.lng" placeholder="请输入经度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="lat">
              <el-input v-model="activityForm.lat" placeholder="请输入纬度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用" prop="cost">
              <el-input v-model="activityForm.cost" placeholder="请输入费用" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动发起人" prop="organizerId">
              <el-select
                v-model="activityForm.organizerId"
                placeholder="请选择活动发起人"
                clearable
                filterable
                remote
                :remote-method="organizerRemoteMethod"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <div style="float: left">
                    <el-avatar size="small" :src="item.pic" />
                  </div>
                  <div style="float: left">
                    {{ item.name }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动开始时间" prop="startTime">
              <el-date-picker
                v-model="activityForm.startTime"
                type="datetime"
                placeholder="请选择活动开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动结束时间" prop="endTime">
              <el-date-picker
                v-model="activityForm.endTime"
                type="datetime"
                placeholder="请选择活动结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动发起人介绍" prop="organizerIntroduce">
              <el-input
                v-model="activityForm.organizerIntroduce"
                placeholder="请输入活动发起人介绍"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名开始时间" prop="enrollStartTime">
              <el-date-picker
                v-model="activityForm.enrollStartTime"
                type="datetime"
                placeholder="请选择报名开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报名结束时间" prop="enrollEndTime">
              <el-date-picker
                v-model="activityForm.enrollEndTime"
                type="datetime"
                placeholder="请选择报名结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="允许报名人数" prop="open">
              <el-input v-model="activityForm.open" placeholder="请输入允许报名人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动介绍" prop="content">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :default-config="toolbarConfig"
                :mode="mode"
              />
              <Editor
                v-model="html"
                style="height: 300px; overflow-y: hidden;"
                :default-config="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phoneNumber">
              <el-input v-model="activityForm.phoneNumber" placeholder="请输入手机号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信号" prop="wechatNumber">
              <el-input v-model="activityForm.wechatNumber" placeholder="请输入微信号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="活动类型" prop="type2">
              <el-radio-group v-model="activityForm.type2">
                <el-radio :label="1">
                  官方
                </el-radio>
                <el-radio :label="2">
                  营地
                </el-radio>
                <el-radio :label="3">
                  其它
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="是否置顶" prop="isTop">
              <el-radio-group v-model="activityForm.isTop">
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="是否显示" prop="isShow">
              <el-radio-group v-model="activityForm.isShow">
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="activityForm.sort" placeholder="请输入排序" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="activityDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="activityFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog id="image" width="540px" :visible.sync="imageDialogVisible">
      <img
        width="500px"
        object-fit="contain"
        :src="imageUrl"
        alt=""
      >
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="活动名称">
        <el-input v-model="searchForm.name" placeholder="请输入活动名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="activityPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="id" label="序号" />
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createTime">{{ scope.row.createTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称" />
      <el-table-column prop="organizerName" label="活动发起人" />
      <el-table-column prop="address" label="活动地址" />
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">预告</span>
          <span v-if="scope.row.status === 2">进行中</span>
          <span v-if="scope.row.status === 3">已结束</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="activityPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addActivity,
  deleteActivity,
  updateActivity,
  selectActivityInfo,
  selectActivityList
} from '@/api/main/activity'
import { selectCampList } from '@/api/main/camp'
import { selectUserScoreList } from '@/api/main/userScore'
import { upload2 } from '@/api/main/file'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar },
  data () {
    return {
      activityDialogVisible: false,
      activityFormTitle: '',
      activityForm: {
        id: '',
        name: '',
        cover: '',
        startTime: '',
        endTime: '',
        campId: '',
        campName: '',
        address: '',
        lng: '',
        lat: '',
        cost: '',
        organizerId: '',
        organizerIntroduce: '',
        enrollStartTime: '',
        enrollEndTime: '',
        open: '',
        content: '',
        phoneNumber: '',
        wechatNumber: '',
        type: 1,
        type2: '',
        isTop: '',
        isShow: '',
        sort: ''
      },
      activityFormRules: {
        name: [{ required: true, message: '活动名称不能为空', trigger: ['blur', 'change']}],
        cover: [{ required: true, message: '活动封面不能为空', trigger: ['blur', 'change']}],
        address: [{ required: true, message: '地址不能为空', trigger: ['blur', 'change']}],
        startTime: [{ required: true, message: '活动开始时间不能为空', trigger: ['blur', 'change']}],
        endTime: [{ required: true, message: '活动结束时间不能为空', trigger: ['blur', 'change']}],
        organizerId: [{ required: true, message: '活动发起人不能为空', trigger: ['blur', 'change']}],
        enrollStartTime: [{ required: true, message: '报名开始时间不能为空', trigger: ['blur', 'change']}],
        enrollEndTime: [{ required: true, message: '报名结束时间不能为空', trigger: ['blur', 'change']}],
        open: [{ required: true, message: '允许报名人数不能为空', trigger: ['blur', 'change']}],
        phoneNumber: [{ required: true, message: '手机号不能为空', trigger: ['blur', 'change']}],
        type2: [{ required: true, message: '活动类型不能为空', trigger: ['blur', 'change']}]
      },
      activityPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        type: 1
      },
      campList: [],
      userList: [],
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      imageDialogVisible: false,
      imageUrl: '',
      fileList: [],
      mode: 'default',
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容',
        MENU_CONF: {
          uploadImage: {
            async customUpload (file, insertFn) {
              const data = new FormData()
              data.append('file', file)
              let url = 'https://oss-quye.huahuihongxin.com/' + await upload2(data)
              insertFn(url, '', url)
            }
          }
        }
      }
    }
  },
  created () {
    selectActivityList(this.searchForm).then(res => {
      this.activityPage = res
    })
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor != null) {
      editor.destroy()
    }
  },
  methods: {
    activityDialogClose () {
      this.$refs.activityFormRef.resetFields()
      this.fileList = []
      this.campList = []
      this.userList = []
      this.editor.clear()
    },
    activityFormSubmit () {
      if (this.activityFormTitle === '活动详情') {
        this.activityDialogVisible = false
        return
      }
      this.activityForm.cover = this.fileList.map(item => item.name).join(',')
      this.activityForm.content = this.editor.getHtml()
      this.$refs.activityFormRef.validate(async valid => {
        if (valid) {
          if (this.activityFormTitle === '新增活动') {
            await addActivity(this.activityForm)
          } else if (this.activityFormTitle === '修改活动') {
            await updateActivity(this.activityForm)
          }
          this.activityPage = await selectActivityList(this.searchForm)
          this.activityDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.activityFormTitle = '新增活动'
      this.activityDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteActivity(row.id)
        if (this.activityPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.activityPage = await selectActivityList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.activityFormTitle = '修改活动'
      this.activityDialogVisible = true
      this.selectActivityInfoById(row.id)
    },
    handleInfo (index, row) {
      this.activityFormTitle = '活动详情'
      this.activityDialogVisible = true
      this.selectActivityInfoById(row.id)
    },
    selectActivityInfoById (id) {
      selectActivityInfo(id).then(res => {
        this.activityForm.id = res.id
        this.activityForm.name = res.name
        this.activityForm.cover = res.cover
        if (res.cover) {
          const picUrl = res.cover.split(',')
          this.fileList = []
          for (let i = 0; i < picUrl.length; i++) {
            this.fileList.push({
              name: picUrl[i],
              url: this.baseUrl + picUrl[i]
            })
          }
        }
        this.activityForm.startTime = res.startTime
        this.activityForm.endTime = res.endTime
        this.activityForm.campId = res.campId
        this.activityForm.campName = res.campName
        this.campList.push({
          id: res.campId,
          name: res.campName
        })
        this.activityForm.address = res.address
        this.activityForm.lng = res.lng
        this.activityForm.lat = res.lat
        this.activityForm.cost = res.cost
        this.activityForm.organizerId = res.organizerId
        this.userList.push({
          id: res.organizerId,
          name: res.organizerName,
          pic: res.organizerPic
        })
        this.activityForm.organizerIntroduce = res.organizerIntroduce
        this.activityForm.enrollStartTime = res.enrollStartTime
        this.activityForm.enrollEndTime = res.enrollEndTime
        this.activityForm.open = res.open
        this.activityForm.content = res.content
        this.html = res.content
        this.activityForm.phoneNumber = res.phoneNumber
        this.activityForm.wechatNumber = res.wechatNumber
        this.activityForm.type2 = res.type2
        this.activityForm.isTop = res.isTop
        this.activityForm.isShow = res.isShow
        this.activityForm.sort = res.sort
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectActivityList(this.searchForm).then(res => {
        this.activityPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectActivityList(this.searchForm).then(res => {
        this.activityPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectActivityList(this.searchForm).then(res => {
        this.activityPage = res
      })
    },
    campChange (value) {
      if (value) {
        let camp = this.campList.find(item => item.id === value)
        this.activityForm.campName = camp.name
        this.activityForm.address = camp.adress
        this.activityForm.lng = camp.lng
        this.activityForm.lat = camp.lat
      } else {
        this.activityForm.campName = ''
        this.activityForm.address = ''
        this.activityForm.lng = ''
        this.activityForm.lat = ''
      }
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload2(data).then(res => {
        this.fileList.push({
          name: res,
          url: this.baseUrl + res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file) {
      this.imageUrl = file.url
      this.imageDialogVisible = true
    },
    onCreated (editor) {
      this.editor = Object.seal(editor)
    },
    campRemoteMethod (query) {
      if (query !== '') {
        selectCampList({ name: query }).then(res => {
          this.campList = res.list
        })
      } else {
        this.campList = []
      }
    },
    organizerRemoteMethod (query) {
      if (query !== '') {
        selectUserScoreList({ name: query }).then(res => {
          this.userList = res.list
        })
      } else {
        this.userList = []
      }
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css">
#activity #image .el-dialog__body {
  padding: 20px;
}
</style>
