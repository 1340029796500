import axios from '@/common/axios'
import qs from 'qs'

export function addUserScore (data) {
  return axios({
    method: 'post',
    url: '/score/add',
    data: qs.stringify(data)
  })
}

export function selectUserScoreDetail (query) {
  return axios({
    method: 'get',
    url: '/score/scoreList',
    params: query
  })
}

export function selectUserScoreList (query) {
  return axios({
    method: 'get',
    url: '/wxuser/list',
    params: query
  })
}

export function deleteUser (query) {
  return axios({
    method: 'delete',
    url: '/wxuser/delete',
    params: query
  })
}

export function userStatistics (query) {
  return axios({
    method: 'get',
    url: '/index/user/statistics',
    params: query
  })
}
