import axios from '@/common/axios'
import qs from 'qs'

export function addActivity (data) {
  return axios({
    method: 'post',
    url: '/activity/add',
    data: qs.stringify(data)
  })
}

// export function deleteActivity (id) {
//   return axios({
//     method: 'delete',
//     url: '/activity/delete/' + id
//   })
// }

export function deleteActivity (id) {
  return axios({
    method: 'post',
    url: '/activity/app/cancel',
    params: { id: id }
  })
}

export function updateActivity (data) {
  return axios({
    method: 'put',
    url: '/activity/update',
    data: qs.stringify(data)
  })
}

export function selectActivityInfo (id) {
  return axios({
    method: 'get',
    url: '/activity/info/' + id
  })
}

export function selectActivityList (query) {
  return axios({
    method: 'get',
    url: '/activity/list',
    params: query
  })
}
