import axios from '@/common/axios'

export function upload (data) {
  return axios({
    method: 'post',
    url: '/file/upload',
    data: data
  })
}

export function download (query) {
  return axios({
    method: 'get',
    url: '/file/download',
    params: query,
    responseType: 'blob'
  })
}

export function upload2 (data) {
  return axios({
    method: 'post',
    url: '/file2/upload',
    data: data
  })
}

export function getUuid () {
  return axios({
    method: 'get',
    url: '/file2/oss/getUuid'
  })
}
